import React from "react"
import { Helmet } from "react-helmet"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

const Layout = props => {
  let path
  if (!props.bodyClass) {
    path = props.children[1].props.className
  }
  const pageDescription = {
    aboutUs: " - About Us",
    bookselling: " - Bookselling",
    events: " - Events",
    schoolBookFair: " - School Book Fairs",
    support: " - Support",
    contact: " - Contact",
  }
  const dataForThisPage = pageDescription[path]
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Moonwood</title>
        {/* <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
        <meta name="description" content={`Moonwood${dataForThisPage !== undefined ? dataForThisPage : ""}`} />
        <body className={props.bodyClass ? props.bodyClass : ""} />
      </Helmet>
      <Header headerPages={props.headerPages} pages={props.pages} headerContact={props.headerContact} />
      {props.children}
      {props.pages ? <Footer /> : null}
    </>
  )
}

export default Layout
