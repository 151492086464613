import React from "react"

import { Link } from "gatsby"

import Logo from "../../assets/images/logo.svg"
import LogoPages from "../../assets/images/logo-pages.svg"
import Facebook from "../../assets/images/facebook.svg"
import Instagram from "../../assets/images/instagram.svg"
import Twitter from "../../assets/images/twitter.svg"

const Header = props => {
  React.useEffect(() => {
    const eyeBall = () => {
      const eye = document.querySelectorAll(".eye")

      for (let i = 0; i < eye.length; i++) {
        //        eye[i].addEventListener('mouseover', hideImg);
        const left = eye[i].getBoundingClientRect().left
        const top = eye[i].getBoundingClientRect().top

        let x = left + eye[i].clientWidth / 2
        let y = top + eye[i].clientHeight / 2

        let radian = Math.atan2(window.event.pageX - x, window.event.pageY - y)
        let rot = radian * (180 / Math.PI) * -1 + 270

        eye[i].style.transform = "rotate(" + rot + "deg)"
      }
    }

    window.addEventListener("mousemove", eyeBall)
    return () => {
      window.removeEventListener("mousemove", eyeBall)
    }
  }, [])

  React.useEffect(() => {
    document.getElementById("burger").addEventListener("click", function () {
      document.querySelector("header").classList.toggle("burger-opened")
    })
  }, [])

  return (
    <header className={props.headerPages ? "headerPages" : null}>
      <div className="header-inner container-wide">
        <div id="logo">
          <Link to="/">
            {props.pages ? <img src={LogoPages} alt="MoonwoodLogo" /> : <img src={Logo} alt="MoonwoodLogo" />}
            <div className="eyes">
              <div className="eye"></div>
              <div className="eye"></div>
            </div>
          </Link>
        </div>
        <nav className="right">
          <ul className="no-style-type">
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/bookselling">Bookselling</Link>
            </li>
            <li>
              <Link to="/school-book-fairs">School Book Fairs</Link>
            </li>
            <li>
              <Link to="/support">Support</Link>
            </li>
            <li>
              <Link to="/events">Events</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li className="separated">
              <a href="https://moonwood-books.store/">Store</a>
            </li>
            <ul className="social-icons">
              <li>
                <a href="https://www.facebook.com/moonwood.childrens.books/">
                  <img src={Facebook} alt="FacebookIcon" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/moonwood_books/">
                  <img src={Instagram} className="instagram" alt="InstagramIcon" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/@MoonwoodB">
                  <img src={Twitter} alt="TwitterIcon" />
                </a>
              </li>
            </ul>
          </ul>
        </nav>
        <button id="burger" aria-label="menu">
          <span className="burger"></span>
        </button>
      </div>
    </header>
  )
}

export default Header
