import React from "react"
import { Link } from "gatsby"

import Logo from "../../assets/images/logo-footer.svg"
import Facebook from "../../assets/images/facebook.svg"
import Twitter from "../../assets/images/twitter.svg"
import Instagram from "../../assets/images/instagram.svg"

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="background bgFooter"></div>
      <div className="footer-inner container-wide">
        <div className="left">
          <Link to="/">
            <img src={Logo} alt="Moonwood Logo" />
          </Link>
        </div>
        <div className="right">
          <ul className="no-style-type">
            <li className="social">
              <a href="https://www.facebook.com/moonwood.childrens.books/">
                <img src={Facebook} alt="FacebookIcon" />
              </a>
              <a href="https://www.instagram.com/moonwood_books/">
                <img src={Instagram} alt="InstagramIcon" />
              </a>
              <a href="https://twitter.com/@MoonwoodB">
                <img src={Twitter} alt="TwitterIcon" />
              </a>
            </li>
          </ul>
          <p>&copy; 2020 Moonwood, All rights reserved</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
